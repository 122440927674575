// src/components/Chat.tsx
import React, { useEffect, useState, useRef, MutableRefObject, useCallback } from 'react';
import { Container } from '@mui/material';
import { MessageDto } from '../models/MessageDto';
import { Header } from './Header';
import Input from './Input';
import MessageFeed from './MessageFeed';
import { logThreadId } from '../helpers/Logger';
import { Requester } from '../helpers/Requester';
import { InitialMessage } from '../models/InitiateAssistantThreadResultDto';
import { AssistantDto } from '../models/AssistantDto';


const Chat: React.FC = () => {
  const [isWaiting, setIsWaiting] = useState<boolean>(false);
  const [messages, setMessages] = useState<Array<MessageDto>>(new Array<MessageDto>());
  const [input, setInput] = useState<string>('');
  const [threadId, setThreadId] = useState<string | null>(null);
  const [assistantId, setAssistantId] = useState<string | null>(null);
  const [initialMessage, setInitialMessage] = useState<InitialMessage | null>(null);
  const [assistants, setAssistants] = useState<Array<AssistantDto | null>>([]);
  const [assistant, setAssistant] = useState<AssistantDto | null>(null);
  const [isAdmin, setIsAdmin] = useState<boolean>(false);

  const chatboxGridRef = useRef<unknown>(null) as MutableRefObject<HTMLDivElement | null>;

  useEffect(() => {
      const initChatBot = async () => {
      setIsWaiting(true);
        const currentUser = await Requester.getCurrentUser();
        setIsAdmin(currentUser?.isAdmin ?? false);
        if (currentUser?.organization?.assistants == null) {
          return;
        }
        const assistants = currentUser?.organization?.assistants;
        setAssistants(assistants);
        const assistant = currentUser?.organization?.assistants[0];

        setAssistant(assistant ?? null);

      setIsWaiting(false);
    };

    initChatBot();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const initiateThread = async () => {

      const initiateThreadResult = await Requester.initiateThread(assistant?.id ?? null);
      if (initiateThreadResult === null) {
        // TODO: How to handle non-initialized state correctly?
        return;
      }

      if (threadId !== null) {
        logThreadId(threadId);
      }

      setThreadId(initiateThreadResult.threadId);
      setAssistantId(initiateThreadResult.assistantId);
      setInitialMessage(initiateThreadResult.initialMessage);
    }
    if(assistant != null)
    {
      initiateThread();
    }
  }, [assistant]);

  useEffect(() => {
    const current = chatboxGridRef?.current;
    if (typeof current !== 'object' || current === null) {
      return;
    }

    if (!('scrollTo' in current) || !('scrollHeight' in current)) {
      return;
    }

    if (typeof current.scrollTo !== 'function') {
      return;
    }

    if (current.scrollTo !== null && current.scrollTo !== undefined) {
      current.scrollTo({ behavior: 'smooth', top: current.scrollHeight, left: 0 });
    }
  }, [messages]);

  useEffect(() => {
    if (initialMessage === null) {
      return;
    }

    setMessages([
      {
        content: initialMessage.text,
        isUser: false,
        createdAt: ((new Date()).getTime() / 1000),
      },
    ]);
  }, [threadId, initialMessage]);

  const createNewMessage = (content: string, isUser: boolean, createdAt: number) => {
    const newMessage = new MessageDto({ 
      isUser: isUser, 
      content: content, 
      createdAt: createdAt, 
    });

    return newMessage;
  };

  const addMessageAndEmptyInput = async (sentMessageTimeInMilliseconds: number) => {
    messages.push(createNewMessage(input, true, sentMessageTimeInMilliseconds / 1000));
    setMessages([...messages]);
    setInput('');
    setIsWaiting(true);
  } 

  const handleSendMessage = async () => {
    const sentMessageTimeInMilliseconds = (new Date()).getTime();
    addMessageAndEmptyInput(sentMessageTimeInMilliseconds);
    if (threadId === null || assistantId === null) {
      return;
    }

    // Send a message to the thread
    const lastMessage = await Requester.sendMessage(threadId, input, assistantId);
    setIsWaiting(false);

    // Print the last message coming from the assistant
    if (lastMessage) {
      const receivedMessageTimeInMilliseconds = (new Date()).getTime();
      setMessages([...messages, createNewMessage(lastMessage, false, receivedMessageTimeInMilliseconds / 1000)]);
      console.log(`Message response time is ${(receivedMessageTimeInMilliseconds - sentMessageTimeInMilliseconds) / 1000}s`)
    }
  };

  const setAssistantCallback = useCallback(
      async (a: AssistantDto | null) => {
        setAssistant(a);
      }, []);

  // detect enter key and send message
  const handleKeyPress = (e: React.KeyboardEvent<HTMLDivElement>) => {
    if (e.key === 'Enter') {
      handleSendMessage();
    }
  };

  return (
    <Container>
      <Header assistant={assistant} assistants={assistants} isAdmin={isAdmin} setAssistant={setAssistantCallback}/>
      <MessageFeed chatboxGridRef={chatboxGridRef as MutableRefObject<HTMLDivElement>} messages={messages} />
      <Input 
        isWaiting={isWaiting} 
        input={input} 
        setInput={setInput} 
        handleKeyPress={handleKeyPress} 
        handleSendMessage={handleSendMessage} 
      />
    </Container>
  );
};

export default Chat;
